<template>
  <v-container tag="section">
  <v-row v-if="$route.params.option != 'crear'">
    <v-col cols="12" sm="8" md="8" lg="8" class="pa-0 text-right">
      <v-spacer />
      <template>
        <span class="px-2 font-weight-bold">Traducir:</span>
        <v-btn icon color="sidebar" @click="disheLang('en')">
          <div style="font-size:18px"><flag iso="us" /></div>
        </v-btn>
        <v-btn icon color="sidebar" @click="disheLang('fr')">
          <div style="font-size:18px"><flag iso="fr" /></div>
        </v-btn>
        <v-btn icon color="sidebar" @click="disheLang('zh')">
          <div style="font-size:18px"><flag iso="cn" /></div>
        </v-btn>
      </template>
    </v-col>
  </v-row>
  <validation-observer ref="dishe_form" v-slot="">
  <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" class="pb-0">
      <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
        <v-text-field v-model="dishe.name" dense rounded single-line prepend-inner-icon="mdi-pasta" label="Nombre del Plato" outlined :error-messages="errors[0]"></v-text-field>
      </validation-provider>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" class="pt-0">
      <validation-provider name="Descripción" vid="description" rules="required|max:180"  v-slot="{ errors }">
       <v-textarea
       label="Descripción"
        filled
        rows="4"
        row-height="30"
        shaped
        auto-grow
        v-model="dishe.description"
        :class="{'error-editor': errors[0]}"
        />
       <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
      </validation-provider>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" sm="8" md="8" lg="8" class="pb-0" >
      <v-card flat class="mt-2">
         <validation-provider name="Alérgenos" vid="allergens" v-slot="{ errors }">
        <v-card-title class="primary--text title-card" :class="{'error--text': errors[0]}">Componentes alérgenos</v-card-title>
        <v-card-text class="outlined-card scrollable" style="height:15rem">
          <v-row>
            <v-col cols="12" sm="12" md="6" class="py-0" v-for="(allergen, i) in allergens" :key="i">
              <v-checkbox
                :label="allergen.name"
                color="info"
                v-model="dishe.allergens"
                :value="allergen.id"
                hide-details
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
            <v-text-field class="d-none" v-model="dishe.allergens"></v-text-field>
            <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
          </validation-provider>
      </v-card>
    </v-col>
    <v-col cols="12" sm="8" md="8" lg="8" class="pb-0" >
      <v-card flat class="mt-2">
        <v-card-title class="primary--text title-card">Imágen del Plato</v-card-title>
        <v-card-text class="align-center justify-center pa-0" tyle="height: 300px; width:700px" v-if="cargaInicialImagen" :class="{'imagezone': !cargaInicialImagen}">
          <template>
            <div class="v-responsive grey v-image lighten-2" :class="{'d-none': !cargaInicialImagen}" style="height: 300px; width:700px">
              <div id="imagePreviewDishe" class="v-image__image v-image__image--cover">
              </div>
               <v-overlay absolute :opacity="0.1" :value="true" color="light-blue lighten-5">
                <v-btn icon x-large color="orange lighten-2" @click="deleteImage">
                  <v-icon size="40">mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-overlay>
            </div>
          </template>
        </v-card-text>
        <v-card-text class="align-center justify-center pa-0" tyle="height: 300px; width:700px" v-else :class="{'imagezone': cargaInicialImagen}">
          <template>
            <div class="v-responsive v-image grey lighten-2" :class="{'d-none': cargaInicialImagen}" style="height: 300px; width:700px">
              <croppa
                :width="700"
                :height="300"
                :quality="1"
                style="margin:auto"
                placeholder="Elige la imagen del plato"
                :placeholder-font-size="18"
                v-model="imagenPlato"
                initial-position="center"
                :show-remove-button="true"
                remove-button-color="black"
              >
              </croppa>
            </div>
          </template>
        </v-card-text>

        <!-- <validation-provider name="Imagen principal" vid="image" rules="" v-slot="{ errors }">
          <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
        </validation-provider> -->
      </v-card>
    </v-col>
  </v-row>
      <v-navigation-drawer
        v-model="side"
        color="sidebar_right"
        permanent
        right
        style="z-index: 0 !important"
        :absolute="!$vuetify.breakpoint.xs"
        :width="$vuetify.breakpoint.xs ? '100%' : '30%'"
        disable-resize-watcher
        disable-route-watcher
        stateless
      >
        <v-row>
          <v-col cols="12" class="py-0">
            <v-card flat class="mt-1 px-4 transparent">
              <v-card-title class="primary--text title-card">Publicar</v-card-title>
              <v-card-text class="outlined-card">
                <v-row>
                  <v-col cols="12" class="px-1 py-2">
                    <!-- <v-btn small class="py-2 font-weight-bold left" rounded outlined color="primary" @click="validarDashe(0)">
                      Guardar borrador
                    </v-btn> -->
                    <v-switch
                      v-model="dishe.visibility"
                      :label="visibilidadNombre"
                      @change="cambiarVisibilidad($event)"
                      color="success"
                      ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-list dense>
                      <v-list-item style="min-height: 20px !important;" v-if="$route.params.option != 'crear'">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="primary--text text--lighten-1 d-flex flex-row align-center" style="font-size:1rem !important">
                            <span class="pr-3">Visibilidad: </span>
                            <template>
                              <span class="font-weight-bold">{{ dishe.visibility | visible }}</span>
                            </template>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item style="min-height: 20px !important;">
                        <v-list-item-content class="py-0">
                          <v-list-item-title class="primary--text text--lighten-1" style="font-size:1rem !important">

                          <span class="pr-3" v-text="$route.params.option != 'crear' ? 'Publicado: ' : 'Publicar: '" ></span>
                          <template v-if="$route.params.option != 'crear'">
                            <strong>{{ dishe.publication_date | smartDate }}</strong>
                          </template>
                          <b v-else>Inmediatamente</b>
                        </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- *******SECCION DE PRECIOS****** -->
        <v-row>
          <v-col cols="12" class="py-0" >
            <v-card flat class="my-0 px-4 transparent">
              <validation-provider name="Precios" vid="prices" rules="required" v-slot="{ errors }">
              <v-card-title class="primary--text title-card py-0" :class="{'error--text': errors[0]}">Precios</v-card-title>
              <v-card-text class="outlined-card scrollable" style="height:12rem">
                <v-row v-for="(prices,index) in dishe.price" :key="index" v-show="dishe.price[0].name != ''">
                  <v-col class="col-12">
                    <strong>Variación:</strong> {{prices.name}}
                  </v-col>
                  <v-col v-for="(price,i) in prices.prices" :key="i">
                    {{ formatNumber(price.amount,coinCountry[price.coin]) }}
                  </v-col>
                  <br>
                </v-row>
              </v-card-text>
                <v-text-field class="d-none" v-model="dishe.categories"></v-text-field>
                <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
              </validation-provider>
              <v-card-actions class="d-flex justify-center">
                <v-btn dense class="py-2 font-weight-bold" rounded outlined color="primary" @click="modal_prices = true" :small="$vuetify.breakpoint.smAndDown">Editar precios</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <!-- *******SECCION DE CATEGORIAS****** -->
        <v-row>
          <v-col cols="12" class="py-0" >
            <v-card flat class="my-0 px-4 transparent">
              <validation-provider name="Categorías" vid="categories" rules="required" v-slot="{ errors }">
              <v-card-title class="primary--text title-card py-0" :class="{'error--text': errors[0]}">Categorías del Plato</v-card-title>
              <v-card-text class="outlined-card scrollable" style="height:12rem">
                <v-row>
                  <v-col cols="12">
                    <template v-for="(category, i) in categories">
                      <v-checkbox
                        :label="category.name.es"
                        color="info"
                        v-model="dishe.categories"
                        :value="category.id"
                        hide-details
                        :key="i"
                      ></v-checkbox>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
                <v-text-field class="d-none" v-model="dishe.categories"></v-text-field>
                <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
              </validation-provider>
              <v-card-actions class="d-flex justify-center">
                <v-btn dense class="py-2 font-weight-bold" rounded outlined color="primary" @click="modal_catg = true" :small="$vuetify.breakpoint.smAndDown">Añadir nueva categoría</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-card-actions class="d-flex justify-center pr-0 pb-0">
              <v-btn class="mr-0 font-weight-bold" large color="primary" @click="validarDashe(1)" v-text="dishe.visibility == 0 ? 'Publicar' : $route.params.option != 'crear' ? 'Actualizar' : 'Publicar'">
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </validation-observer>

    <!-- *******MODAL DE TRADUCCIONES****** -->
    <base-material-modal v-model="modal_dishe" icon="mdi-flag" :loadcard="saving_dishe" :title="`Añadir Traducción (${lang})`" width="900">
      <template v-slot:content>
        <validation-observer ref="dishe_lang" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Título" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field v-model="traslate.name" dense rounded single-line prepend-inner-icon="mdi-playlist-edit" label="Nombre del plato" outlined :error-messages="errors[0]"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Descripcion" vid="description" rules="required" v-slot="{ errors }">
                <quill-editor
                v-model="traslate.description"
                :class="{'error-editor': errors[0]}"
                :options="editorOption"/>
                <div class="v-messages theme--light error--text"> <span class="v-messages__message" v-text="errors[0]"></span></div>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed
            small
            @click="modal_dishe = false"
            :disabled="saving_dishe"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" tile small :loading="saving_dishe" @click="validateLang">
            Guardar traducción
          </v-btn>
      </template>
    </base-material-modal>


    <!-- *******MODAL DE CATEGORIAS****** -->
    <base-material-modal v-model="modal_catg" icon="mdi-animation-outline" :loadcard="saving_catg" title="Añadir Categoría">
      <template v-slot:content>
        <validation-observer ref="category_dishe" v-slot="">
          <v-row>
            <v-col cols="12" class="pb-0">
              <span>Nombre</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined dense rounded prepend-inner-icon="mdi-playlist-edit" v-model="dishe_category.name" :error-messages="errors[0]" hint="El nombre será como aparecerá en su sitio" persistent-hint :disabled="saving_catg"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0">
              <span>Descripción</span>
            </v-col>
            <v-col cols="12" class="pb-0">
              <validation-provider name="Descripción" vid="description" rules="required" v-slot="{ errors }">
                 <v-textarea
                    name="Descripcion"
                    auto-grow
                    :error-messages="errors[0]"
                    prepend-inner-icon="mdi-playlist-edit"
                    outlined
                    placeholder=" "
                    v-model="dishe_category.description"
                    :disabled="saving_catg"
                  ></v-textarea>
              </validation-provider>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>
         <v-btn
            depressed
            small
            @click="modal_catg = false"
            :disabled="saving_catg"
            rounded
            style="padding-right: 20px;padding-left: 20px;"
          >
            Descartar
          </v-btn>
          <v-btn color="primary" depressed rounded small :loading="saving_catg" @click="validateCatg" style="padding-right: 20px;padding-left: 20px;">
            Añadir
          </v-btn>
      </template>
    </base-material-modal>

    <!-- *******MODAL DE PRECIOS****** -->
    <base-material-modal v-model="modal_prices" icon="mdi-animation-outline" :loadcard="saving_price" title="Editar precios">
      <template v-slot:content>
        <validation-observer ref="prices_dishe" v-slot="">
          <v-row class="" v-for="(price,index) in dishe.price" :key="index">
            <v-col cols="12" class="pb-0">
              <span>Variación</span>
            </v-col>
            <v-col cols="12" class="pb-0" >
              <validation-provider name="Nombre" vid="name" rules="required" v-slot="{ errors }">
                <v-text-field outlined dense rounded prepend-inner-icon="mdi-playlist-edit" v-model="price.name" :error-messages="errors[0]" hint="El nombre de la variación del producto" persistent-hint :disabled="saving_catg"></v-text-field>
              </validation-provider>
            </v-col>
            <v-col cols="12" class="pb-0" >
              <div class="d-block" v-for="(p,i) in price.prices" :key="i">
                <validation-provider name="Montos" vid="amount" v-slot="{ errors }">
                    <v-text-field
                      v-model="p.amount"
                      :counter="10"
                      :label="labelAmount(p.coin)"
                      :rules="[rules.required,rules.money]"
                      required

                      :error-messages="errors[0]"
                    ></v-text-field>
                    </validation-provider>
              </div>
            </v-col>
            <v-col>
              <v-btn small @click="removePrice(index)" style="margin-top: 20px;"><v-icon>mdi-cancel</v-icon></v-btn>
            </v-col>
          </v-row>
        </validation-observer>
      </template>
      <template v-slot:actions>

         <!-- <v-btn
            depressed
            small
            @click="restorePrices"
            :disabled="saving_catg"
            rounded
            style="padding-right: 20px;padding-left: 20px;"
          >
            Descartar
          </v-btn> -->
          <v-btn class="primary" depressed rounded small :loading="saving_catg" @click="addPrice">Agregar variación</v-btn>
          <v-btn color="primary" depressed rounded small :loading="saving_catg" @click="validatePric" style="padding-right: 20px;padding-left: 20px;">
            Añadir
          </v-btn>
      </template>

    </base-material-modal>



    <v-overlay absolute :value="loading.active" :opacity="loading.opacity" :z-index="10" color="white" class="text-center">
      <v-img
        contain
        max-width="120"
        :src="require('@/assets/Todo-carta-logo.png')"
      ></v-img>
      <v-progress-linear
          color="blue-grey lighten-3"
          indeterminate
          rounded
          height="4"
        ></v-progress-linear>
      <h5 class="blue-grey--text mt-4">{{ loading.text }}</h5>
    </v-overlay>

  </v-container>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import coinSymbol from "../../../mixins/coinSymbol"
import coinCountry from "../../../mixins/coinCountry"


import {quillEditor} from 'vue-quill-editor'
  export default {
    name: 'CreateDish',
    components:{
      quillEditor
    },
    data () {
      return {
        imagenPlato :{},
        cargaInicialImagen:true,
        dishe:{
          description: '',
          name: '',
          price: '',
          visibility: '',
          publication_date:  new Date().toISOString().substr(0, 10),
          categories: [],
          lang: 'es',
          image: '',
          restaurant_id: '',
          allergens: [],
          id: '',
          image_delete: false
        },
        dishe_lang:{
          description: '',
          name: '',
        },
        dishe_category:{
          name:'',
          description: '',
          restaurant_id: '',
          lang: 'es'
        },
        traslate:{
          description: '',
          name: '',
          lang: '',
        },
        loading:{
          text: '',
          opacity: '',
          active: false
        },
        allergens: [],
        categories: [],
        snackbar:{
          text:'',
          active: false,
          type:''
        },
        description:'',
        side: true,
        saving_catg: false,
        saving_price:false,
        modal_catg: false,
        modal_dishe: false,
        modal_prices:false,
        saving_dishe: false,
        editorOption: {
          placeholder: "Descripcion",
          modules: {
            toolbar: [
              ['bold', 'italic', 'underline', 'strike'],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              [{ 'script': 'sub' }, { 'script': 'super' }],
              // [{ 'size': ['small', false, 'large', 'huge'] }],
              // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
              // [{ 'font': [] }],
              [{ 'color': [] }, { 'background': [] }],
              ['clean'],
            ],
          }
        },
        redirect: '',
        restaurant:[],
        coins:[],
        coin:'',
        prices:[],
        rules: {
          required: value => !!value || 'Required.',
          money: value => {
            const pattern = new RegExp(/^\d+(,[0-9]{1,2})/)
            const pattern2 =  new RegExp(/^\d+$/)
            return (pattern.test(value) || pattern2.test(value)) || 'Formato incorrecto 20,00'
          }
        }

      }
    },
    created(){
      this.init();
    },
    beforeRouteUpdate (to, from, next) {
      let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.option) || to.params.option === 'crear'){
          this.resetDishe();
          if(this.$store.getters['auth/isAdmin']){
            this.dishe.restaurant_id = to.params ? to.params.id : '';
            this.dishe_category.restaurant_id = to.params ? this.$route.params.id : '';
            this.redirect = `/tc-admin/restaurante/${this.dishe.restaurant_id}/administrar/platos`;
          }
          else{
            this.dishe.restaurant_id =  this.$store.getters['auth/restaurant'];
            this.dishe_category.restaurant_id =  this.$store.getters['auth/restaurant'];
            this.redirect = '/tc-admin/platos';
          }
          this.getForm();
          next();
        }
        else{
          next('/tc-admin/error');
        }
    },
    methods: {
      init(){
        if(this.$store.getters['auth/isAdmin']){
          this.dishe.restaurant_id = this.$route.params ? this.$route.params.id : '';
          this.dishe_category.restaurant_id = this.$route.params ? this.$route.params.id : '';
          this.redirect = `/tc-admin/restaurante/${this.dishe.restaurant_id}/administrar/platos`;
        }
        else{
          this.dishe.restaurant_id =  this.$store.getters['auth/restaurant'];
          this.dishe_category.restaurant_id =  this.$store.getters['auth/restaurant'];
          this.redirect = '/tc-admin/platos';
        }

        if(this.$route.params.option && this.$route.params.option != "crear"){
          this.dishe.id =  this.$route.params.option;
          this.getDishe();
        }
        else
          this.getForm();

        this.initPrice();
      },
      async initPrice(){

        const url = `api/restaurants/settings?restaurant_id=${this.idRestaurant}`;
        try{
          await this.$api.get(url).then(res=>{
            //Establezco las variables
            this.restaurant.coins = JSON.parse(res.data.data.settings.coins);
            this.coins = JSON.parse(res.data.data.settings.coins);
            var moneda;
            var exists;
            //Compruebo si existe un precio previo
            if (this.dishe.price) {
              //Convierto en json el precio
              this.dishe.price = JSON.parse(this.dishe.price);
              //Copio el array de los precios
              var variation = this.clonar(this.dishe.price);
              //comprobar si fue quitada alguna moneda
              for (let i = 0; i < variation.length; i++) {
                const prices = variation[i];
                for (let o = 0; o < prices.prices.length; o++) {
                  const price = prices.prices[o];
                    for (let a = 0; a < this.coins.length; a++) {
                      const coins = this.coins[a];
                      exists = false;
                      if (price.coin == coins.cod) {
                        exists = true;
                        break;
                      }
                  }
                  if (!exists) {
                      this.dishe.price[i].prices.splice(o, 1);
                    }
                }
              }


              //comprobar si fue añadida una nueva moneda
              for (let a = 0; a < variation.length; a++) {
                const vari = variation[a];
                //Recorro las monedas que el restaurante tiene
                for (let e = 0; e < this.coins.length; e++) {
                  const coins = this.coins[e];
                  //Recorro los precios de la variacion
                  for (let i = 0; i < vari.prices.length; i++) {
                    const prices = vari.prices[i];
                    exists = false;
                    moneda = coins.cod;
                    //Comparo si existe una moneda en los precios actuales
                    if (prices.coin == coins.cod) {
                      exists = true
                      break;
                    }
                  }
                  //Si la empresa tiene una nueva moneda, se agrega la moneda al plato.
                  if (!exists) {
                      this.dishe.price[a].prices.push({amount:0,coin:moneda});
                    }
                }
              }
            }else{
              this.dishe.price = [this.newPrice()];
            }
          });
        }
        catch(e){
          this.snackbar = {
            text:  JSON.stringify(e),
            type: 'error',
            active: true
          }
        }
      },
      newPrice(){
        let price = {name:"",prices:[]};
          for (let i = 0; i < this.coins.length; i++) {
            const coin = this.coins[i];
            let priceArray = {coin:coin.cod,amount:0};
            price.prices.push(priceArray);
          }
        return price;

      },
      resetDishe(){
        this.dishe = {
          description: '',
          name: '',
          price: '',
          visibility: '',
          publication_date:  new Date().toISOString().substr(0, 10),
          categories: [],
          lang: 'es',
          image: '',
          restaurant_id: '',
          allergens: [],
          id: '',
          image_delete: false
        };

        this.dishe_lang ={
          description: '',
          name: '',
        };
        this.dishe_category = {
          name:'',
          description: '',
          restaurant_id: '',
          lang: 'es'
        };
        this.traslate = {
          description: '',
          name: '',
          lang: '',
        };
        this.$refs.dishe_form.reset();
      },
      async getForm(){
        this.loading = {active: true, opacity: 0.95, text: 'Obteniendo Datos'};
        try{
          const { data } = await this.$api.get(`api/dishes/create?restaurant_id=${this.dishe.restaurant_id}`);

          this.allergens = data.data.allergens;
          this.categories = data.data.categories.map(category =>{
            return {
              name: JSON.parse(category.name),
              id: category.id
            }
          });
          this.loading.active = false;
        }
        catch(error){
          this.load_sale= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las Datos.',
            type: 'error',
            active:true,
          };

        }
      },
      async getDishe(){
        this.loading = {active: true, opacity: 0.95, text: 'Obteniendo Datos'};
        try{
          const { data } = await this.$api.get(`api/dishes/${this.$route.params.option}/edit?restaurant_id=${this.dishe.restaurant_id}`);

          this.dishe_lang.name = data.data.dish.name;
          this.dishe_lang.description = data.data.dish.description;
          this.dishe.description = this.dishe_lang.description.es;
          this.dishe.name = this.dishe_lang.name.es;
          this.dishe.price = data.data.dish.price;
          this.dishe.visibility = data.data.dish.visibility;
          this.dishe.publication_date = data.data.dish.publication_date;
          this.dishe.categories = data.data.dish.categories.map(item => item.id);
          this.dishe.allergens  = data.data.dish.allergens.map(item => item.id);
          // this.dishe.lang: 'es',
          this.dishe.image = data.data.dish.image.url,
          // this.dishe.restaurant_id: '',
          this.allergens = data.data.allergens;
          this.categories = data.data.categories.map(category =>{
            return {
              name: JSON.parse(category.name),
              id: category.id
            }
          });
          /* *****ASIGNAR IMAGEN EN PANTALLA***** */
          const preview = document.querySelector('#imagePreviewDishe');
          preview.style.backgroundImage = `url("${process.env.VUE_APP_IMG_URL_API}images/dishes/${this.dishe.image }")`;
          preview.style.backgroundSize = 'contain';
          preview.style.backgroundPosition = 'center center';
          this.initPrice()

          this.loading.active = false;
        }
        catch(error){
          if(error.response.status == '500'){
            this.$router.push({ name: 'Error-admin', query: {code: '500'}});
          }
          this.load_sale= false;
          this.snackbar = {
            text: 'Lo sentimos, hubo un error al intentar obtener las datos del Plato.',
            type: 'error',
            active:true,
          };
        }
      },
      validarDashe(visibility){
        this.$refs.dishe_form.validate().then(result =>{
          if(result){
            this.dishe.visibility = visibility;
            this.saveDishe();
          }
        })
      },
      validateLang(){
        this.$refs.dishe_lang.validate().then(result =>{
          if(result){
            this.saveDishe(true);
          }
        })
      },
      validateCatg(){
        this.$refs.category_dishe.validate().then(result =>{
          if(result){
            this.addCategory();
          }
        });
      },
      validatePric(){
        this.$refs.prices_dishe.validate().then(result =>{
          if(result){
            this.modal_prices = false
          }
        });
      },
      restorePrices(){
        this.dishe.price = [this.newPrice()];
        this.modal_prices = false;
      },
      async addCategory(){
        this.saving_catg = true;
        try{
          const send_category = await this.$api({
            url: 'api/categories',
            method: 'post',
            data: this.dishe_category
          });
          this.getForm();
          this.saving_catg = false;
          this.modal_catg = false;
          this.dishe_category.name = '';
          this.dishe_category.description = '';
          this.$root.$snakbar({
            text: send_category.data.message,
            type: 'success',
            active: true
          });

        }
        catch(e){
          if(e.response.status == "422"){
            this.$refs.category_dishe.setErrors(e.response.data.data);
          }
          else{
            this.$root.$snakbar({
              text: e.response.data.message,
              type: 'error',
              active: true
            });
          }
          this.saving_catg = false;
        }
      },
      async saveDishe(lang = false){
        const url = 'api/dishes';
        const method = 'post';
        let plato;

        const dataDishe = new FormData;
        dataDishe.append('description', lang ? this.traslate.description : this.dishe.description);
        dataDishe.append('name', lang ? this.traslate.name : this.dishe.name);
        for (let i = 0; i < this.dishe.price.length; i++) {
          const price = this.dishe.price[i];
          dataDishe.append('price[]', JSON.stringify(price));
        }
        //dataDishe.append('price', this.dishe.price);

        dataDishe.append('visibility', this.dishe.visibility);
        dataDishe.append('publication_date', this.dishe.publication_date);
        dataDishe.append('lang', lang ? this.traslate.lang : this.dishe.lang);
        dataDishe.append('restaurant_id', this.dishe.restaurant_id);
        dataDishe.append('image_delete', +this.dishe.image_delete);
        /* if(typeof this.dishe.image === 'object')
          dataDishe.append('image', this.dishe.image); */

        dataDishe.append('id', this.dishe.id);
        for(var i = 0; i < this.dishe.categories.length; i++){
          dataDishe.append('categories[]', this.dishe.categories[i]);
        }

        for(var j = 0; j < this.dishe.allergens.length; j++){
          dataDishe.append('allergens[]', this.dishe.allergens[j]);
        }

        if(lang) this.modal_dishe = false;

        this.loading = {active: true, opacity: 0.75, text: 'Guardando Datos'};
        if(Object.keys(this.imagenPlato).length != 0 ){
          //Compruebo si tiene una imagen valida
          if (this.imagenPlato.hasImage()) {
            plato = this.imagenPlato.promisedBlob((blob) =>{
            },'image/jpeg',0.7);
          }
        }

        if (plato) {
          plato.then(res =>{
            dataDishe.append('image', res, 'filename.jpeg')
          }).
          then(res=>{
            const send_dishe = this.$api({
              url: url,
              method: method,
              data: dataDishe,
            });
            send_dishe.then(res =>{
              this.$root.$snakbar({
                text: res.data.message,
                type: 'success',
                active: true
              });
              if(lang){
                this.getDishe();
              }
              else{
                this.$router.push(`${this.redirect}`);
              }
              this.loading.active = false;
              this.loading.active = false;
            })
          }).catch(e=>{
              if(e.response.status == "422"){
                this.$refs.dishe_form.setErrors(e.response.data.data);
              }
              else{
                this.$root.$snakbar({
                  text: e.response.data.message,
                  type: 'error',
                  active: true
                });
              }
          });
        }else{
          try{
              const send_dishe = await this.$api({
                url: url,
                method: method,
                data: dataDishe,
              });
              this.$root.$snakbar({
                text: send_dishe.data.message,
                type: 'success',
                active: true
              });
              if(lang){
                this.getDishe();
              }
              else{
                this.$router.push(`${this.redirect}`);
              }
              this.loading.active = false;

            }
            catch(e){
              if(e.response.status == "422"){
                this.$refs.dishe_form.setErrors(e.response.data.data);
              }
              else{
                this.$root.$snakbar({
                  text: e.response.data.message,
                  type: 'error',
                  active: true
                });

              }
              this.loading.active = false;
            }
        }

      },
      renderImage(file){
        const image = file.target.files[0] || file.dataTransfer.files
        if(image.length === 0) return;

        this.dishe.image = image;
        this.dishe.image_delete = false;

        const preview = document.querySelector('#imagePreviewDishe');

        let reader = new FileReader();
        reader.readAsDataURL(image);

        reader.onload = () => {
          if (image.type.match('image/png') || image.type.match('image/jpeg') || image.type.match('image/gif')) {
            preview.style.backgroundImage = `url("${ reader.result }")`;
            preview.style.backgroundSize = 'contain';
            preview.style.backgroundPosition = 'center center';
          }
        };
      },
      deleteImage(){
        this.dishe.image = '';
        this.dishe.image_delete = true;
        this.cargaInicialImagen = false;
      },
      disheLang(lang){
        this.traslate.name = this.dishe_lang.name[lang] ? this.dishe_lang.name[lang] : this.dishe_lang.name.es;
        this.traslate.description = this.dishe_lang.description[lang] ? this.dishe_lang.description[lang] : this.dishe_lang.description.es;
        this.traslate.lang = lang;
        this.modal_dishe = true;
      },
      cambiarVisibilidad(e){
         if (e === true) {
          e = 1;
        }else{
          e=0
        }
        this.$refs.dishe_form.validate().then(result =>{
          if(result){
            this.dishe.visibility = e;
            this.saveDishe();
          }
        })
      },
      symbolCoin(val){
        for (let i = 0; i < this.coins.length; i++) {
          if (val == this.coins[i].cod) {
            return this.coins[i].symbol;
          }
        }
      },
      labelAmount(val){
        for (let i = 0; i < this.coins.length; i++) {
          if (val == this.coins[i].cod) {
            return 'Monto en: '+ this.coins[i].coin;
          }
        }

      },
      formatNumber(val,country){
        this.$numeral.locale(country)
        return this.$numeral(val).format('0,0[.]00 $')
      },
      addPrice(){
        this.dishe.price.push(this.newPrice())
      },
      removePrice(key) {
        this.dishe.price.splice(key, 1);
      },
      clonar(obj) {
        if (obj === null || typeof obj !== "object") {
          return obj;
        }
        var temp = obj.constructor();
        for (var key in obj) {
          temp[key] = this.clonar(obj[key]);
        }
        return temp;
      }
    },
    computed:{
      date(){
        const f = new Date();
        return f.toDateString();
      },
      lang(){
        let traslate = '';
        switch(this.traslate.lang){
          case 'en':
            traslate = 'Inglés';
          break;
          case 'fr':
            traslate = 'Francés';
          break;
          case 'zh':
            traslate = 'Chino';
          break;
          default:
            traslate = '';
        }

        return traslate;
      },
      visibilidadNombre(){
        const v = this.dishe.visibility;
        if (v) {
          return 'Visible';
        }else{
          return 'No visible';
        }
      },
      coinSymbol() {
        return coinSymbol
      },
      coinCountry() {
        return coinCountry
      },
      idRestaurant(){
        if(this.$store.getters['auth/isAdmin']){
          return this.$route.params ? this.$route.params.id : '';

        }
        else{
          return this.$store.getters['auth/restaurant'];
        }
      },

    },
    filters:{
      visible(val){
        return val == 1 ? 'Pública' : 'Borrador';
      }
    },

  }
</script>
<style scope>
  .v-data-table__expand-icon{
    color: #00cae3 !important;
    font-size: 30px !important;
  }

  .sidebar_right{
    background: #fbfcfd !important;
  }

  .error-editor .ql-toolbar.ql-snow, .error-editor .ql-container.ql-snow{
    border: 1px solid #ff5252 !important;
  }
  .quill-editor,.editor{
    height: 250px !important
  }
  .editor{
    margin-bottom: 40px
  }
</style>
